import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  strapi_base_url: `https://strapi.dev.marine.cheveo.de`,
  backend_url: `https://api.dev.marine.cheveo.de/dev`,
  media_table_name: `media_assets_dev`,
  cloudfront_image_url: 'https://images.dev.marine.cheveo.de',
  keycloak: {
    keycloakUrl: 'https://identity.dev.marine.cheveo.de',
    realm: 'AIR2_DEV',
    clientId: 'marine-dev',
  },
};
