import { Component, HostListener } from '@angular/core';
import { KeyRecognizerService } from './services/key-recognizer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keyRecognizerService.setKeyPressed(event.key);
  }

  constructor(public keyRecognizerService: KeyRecognizerService) {}
}
