import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeyRecognizerService {
  private keyPressed: Subject<string> = new Subject();

  public getKeyPressedAsObservable(): Observable<string> {
    return this.keyPressed.asObservable();
  }

  public setKeyPressed(key: string): void {
    this.keyPressed.next(key);
  }
}
