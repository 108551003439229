import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CloudfrontCookiesInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {
    console.log('cookies', this.cookieService.getAll());
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log('url', request);
    return next.handle(request);
  }
}
